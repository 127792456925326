import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import portfolioItemStyles from "./portfolioItem.module.css"
import Img from "gatsby-image"

const PortfolioItem = ({ type, title, image, link, classItem }) => (
  <div className={portfolioItemStyles.portfolioItem}>
    <div className="portfolio-wrapper">
      <div className="portfolio-thumb">
        <Img fluid={image.childImageSharp.fluid} />
        <div className="view-icon">
          <Link to={link}>
            <i className={portfolioItemStyles.ionArrowRightC}></i>
          </Link>
        </div>
      </div>
      <Link to={link}>
        <div className="portfolio-caption text-left">
          <div className="work-tag">
            <p>{type}</p>
          </div>
          <h4>{title}</h4>
        </div>
      </Link>
    </div>
  </div>
)

PortfolioItem.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  classItem: PropTypes.string,
}

PortfolioItem.defaultProps = {
  type: ``,
  title: ``,
  image: ``,
  link: ``,
  classItem: `portfolio-item`,
}

export default PortfolioItem
