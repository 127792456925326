import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import IsoTopeGrid from "react-isotope"
import filtersDefault from "../data/filters.json"
import cardsLayout from "../data/cards.json"
import PortfolioItem from "../components/portfolio_item"

export default function PortfolioPage() {
  const [filters, updateFilters] = useState(filtersDefault)
  const data = useStaticQuery(graphql`
    query ProjectsList {
      allWorksJson {
        edges {
          node {
            awards
            category
            client
            cover {
              childImageSharp {
                fluid(maxWidth: 500, maxHeight: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            date
            description
            feature
            media {
              src
            }
            name
            prefix
            tech
            id_project
            id
            link
          }
        }
      }
    }
  `)
  // Filter change handler
  const onFilter = event => {
    const {
      target: { value },
    } = event
    updateFilters(state =>
      state.map(f => {
        if (value === "all" && f.label !== "all") {
          return {
            ...f,
            isChecked: false,
          }
        }
        if (value === "all" && f.label === "all") {
          return {
            ...f,
            isChecked: true,
          }
        }
        if (value !== "all" && f.label === "all") {
          return {
            ...f,
            isChecked: false,
          }
        }

        if (f.label === value) {
          return {
            ...f,
            isChecked: !f.isChecked,
          }
        }

        return f
      })
    )
  }
  return (
    <Layout>
      <SEO
        title="Portfolio"
        description="Have a look at all the projects I have done so far"
      />
      <div className="basic-breadcrumb-area gray-bg pt-70 pb-40">
        <div className="container">
          <div className="basic-breadcrumb text-center">
            <h3 className="">Portfolio</h3>
            <ol className="breadcrumb text-xs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">Portfolio</li>
            </ol>
          </div>
        </div>
      </div>

      <div className="basic-portfolio-area ptb-90">
        <div className="container">
          <div className="filter-menu text-center mb-40">
            {filters.map(f => (
              <button
                id={f.label}
                value={f.label}
                onClick={onFilter}
                className={f.isChecked && "active"}
              >
                {f.label}
              </button>
            ))}
          </div>
          <div></div>
          <div id="portfolio-grid" className="row-portfolio portfolio-style-2">
            <IsoTopeGrid
              gridLayout={cardsLayout}
              noOfCols={4}
              unitWidth={200}
              unitHeight={100}
              filters={filters}
            >
              {data.allWorksJson.edges.map(i => {
                return (
                  <PortfolioItem
                    key={i.node.id_project}
                    classItem="portfolio-item"
                    className="portfolio-item"
                    title={i.node.name}
                    type={i.node.category}
                    link={i.node.prefix}
                    image={i.node.cover}
                  />
                )
              })}
            </IsoTopeGrid>
          </div>
        </div>
      </div>
    </Layout>
  )
}
